import React from "react";
import {AnchorLink} from "../menu";

const items = [
    {
        title: '',
        pages: [
            {
                title: 'Unaty Overview and Definitions',
                slug: 'unaty-overview-and-definitions'
            },
        ]
    },
    {
        title: 'For Unaty users',
        pages: [
            {
                title: 'Unaty + Community Code of Conduct for Users',
                slug: 'unaty-community-code-of-conduct-for-users'
            },
            {
                title: 'Unaty Usage Terms for Users',
                slug: 'unaty-usage-terms-for-users'
            },
            {
                title: 'Unaty Data Privacy Policy for Users',
                slug: 'unaty-data-privacy-policy-for-users'
            },
            {
                title: 'Unaty Website Privacy and Cookie Policy',
                slug: 'unaty-website-privacy-and-cookie-policy'
            }
        ]
    },
    {
        title: 'For Unaty customers',
        pages: [
            {
                title: 'Unaty Community Terms and Conditions for Customers',
                slug: 'unaty-community-terms-and-conditions-for-customers'
            },
            {
                title: 'Unaty Community Data Processing Agreement for Customers',
                slug: 'unaty-community-data-processing-agreement-for-customers'
            }
        ]
    },
];

function getClass(active) {
    return active ? 'hover:text-gray-600 text-selection font-semibold' : 'hover:text-gray-900 text-gray-500';
}

export function HelpSidebar() {

    return <div>
        {items.map(it => <div key={it.title}>
            {it.title && <div className='pt-4 tracking-wide font-bold text-xs uppercase text-gray-600'>{it.title}</div>}
            <ul className="-mt-1">
                {it.pages.map(pg => {
                    const link = `/policies/${pg.slug}`;
                    const active = typeof window !== 'undefined' ? window.location.pathname.startsWith(link) : '';
                    return <li key={pg.title}>
                        <AnchorLink to={link}
                                    className={`py-2 transition-colors duration-200 relative block ${getClass(active)}`}>
                            {pg.title}
                        </AnchorLink>
                    </li>;
                })}
            </ul>
        </div>)}
    </div>
}