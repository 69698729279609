import * as React from "react"
import Page from "../../components/page";
import Header from "../../components/header";
import {PoliciesContainer} from "../../components/policies/container";
import {HelpContainer} from "../../components/help/container";

export default function HelpPage() {
    return (
        <Page menu>
            <Header title='Help Center' />
            <HelpContainer>

            </HelpContainer>
        </Page>
    )
};