import React from "react";
import {HelpSidebar} from "./sidebar";

export function HelpContainer({gray_bg = false, children}) {

    return <div className={`py-10 max-w-7xl px-6 sm:px-6 mx-auto ${gray_bg ? 'bg-gray-50 border-t border-gray-100' : ''}`}>
        <div className="mx-auto flex-col-reverse space-x-4 md:flex-row flex">
            <div className="block w-72 flex-none">
                <HelpSidebar/>
            </div>
            <div className='flex-grow'>
                {children}
            </div>
        </div>
    </div>
}